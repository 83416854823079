<template>
  <div>
    <v-row no-gutters justify="end">
      <v-col cols="12" md="12">
        <Buttons
          :textCancel="$t('GENERAL.CANCEL')"
          v-on:cancel="cancel"
          :cancel_loading="cancel_loading"
          :textSave="$t('GENERAL.SAVE')"
          v-on:save="save"
          :save_loading="save_loading"
          :btnSaveNew="false"
          :btnDuplicate="true"
          :duplicate_loading="duplicate_loading"
          :textDuplicate="$t('GENERAL.DUPLICATE')"
          v-on:duplicate="duplicate"
          :btnDelete="true"
          :delete_loading="delete_loading"
          :textDelete="$t('GENERAL.DELETE')"
          v-on:delete="isShowDialogDelete = true"
        />
      </v-col>
    </v-row>
    <PricingTemplate
      :productPricingTemplateId="productPricingTemplateId"
      :isReady="1"
      :displayName="1"
      :redirect="true"
      :season="true"
      :expiry="true"
      :withCard="true"
      :isShowMarkets="true"
      :withHelp="true"
      ref="pricingTemplate"
    ></PricingTemplate>

    <!-- MarketList -->
    <div v-if="productPricingTemplateId > 0">
      <MarketPricingTemplate
        :parentProductPricingTemplateId="productPricingTemplateId"
      />
    </div>

    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              deleteItem();
              isShowDialogDelete = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import MarketPricingTemplate from "@/view/pages/configure/pricing/components/MarketPricingTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    PricingTemplate,
    MarketPricingTemplate,
    Buttons,
  },
  data() {
    return {
      cancel_loading: false,
      save_loading: false,
      duplicate_loading: false,
      delete_loading: false,
      isShowDialogDelete: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("PRICING.PRICING_TEMPLATE"),
        route: "/configure/pricing",
      },
      { title: this.$t("GENERAL.EDIT") },
    ]);
  },
  watch: {
    $route: "loadData",
  },
  methods: {
    cancel() {
      this.$router.push("/configure/pricing");
    },
    async duplicate() {
      await this.$refs.pricingTemplate.validateAndGetData().then(async data => {
        this.duplicate_loading = true;
        let productPricingTemplate = false;

        productPricingTemplate = data;

        //Here you can add all the following methods required to save more data.
        productPricingTemplate;
        if (productPricingTemplate) {
          await this.$refs.pricingTemplate.duplicate(productPricingTemplate);
        }
        this.duplicate_loading = false;
      });
    },
    async deleteItem() {
      this.$refs.pricingTemplate.deleteItem();
    },
    async save() {
      await this.$refs.pricingTemplate.validateAndGetData().then(async data => {
        this.save_loading = true;
        let productPricingTemplate = false;

        productPricingTemplate = data;

        //Here you can add all the following methods required to save more data.
        productPricingTemplate;
        if (productPricingTemplate) {
          if (productPricingTemplate.productPricingTemplateId === 0) {
            await this.$refs.pricingTemplate.store(productPricingTemplate);
          } else {
            await this.$refs.pricingTemplate.save(productPricingTemplate);
          }
        }
        this.save_loading = false;
      });
    },
  },
  computed: {
    productPricingTemplateId() {
      return this.$route.params.id;
    },
  },
  created() {},
};
</script>
