<template>
  <div>
    <v-dialog
      :key="keyDialogFullscreen"
      v-model="isShowDialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      eager
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card class="mt-6">
          <v-card-title> {{ $t("PRICING.MARKETS") }} </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("PRICING.PRODUCT_PRICING_TEMPLATE") }}
                    </th>
                    <th class="text-left" colspan="2">
                      {{ $t("GENERAL.ACTION") }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  @click="itemPricing.showDetails = !itemPricing.showDetails"
                  v-for="(itemPricing, index) in productPricingTemplateMarket"
                  :key="index"
                >
                  <tr>
                    <td>
                      {{
                        itemPricing.name === "-"
                          ? $t("PRICING.NO_NAME_TABLE")
                          : itemPricing.name
                      }}
                    </td>
                    <td>
                      <span
                        class="content-btn-edit d-print-inline-block mr-2"
                        @click="editItem(itemPricing)"
                      >
                        <a class="btn btn-icon btn-light btn-hover-gray">
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <v-icon color="gray">
                              mdi-pencil
                            </v-icon>
                          </span>
                        </a>
                      </span>

                      <span
                        class="content-btn-delete d-print-inline-block"
                        @click="
                          deleteItem({
                            productPricingTemplateId:
                              itemPricing.productPricingTemplateId,
                            productPricingTemplateFatherId:
                              itemPricing.productPricingTemplateFatherId,
                          })
                        "
                      >
                        <a class="btn btn-icon btn-light btn-hover-danger">
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <v-icon color="red">
                              mdi-delete
                            </v-icon>
                          </span>
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr v-show="itemPricing.showDetails" :key="index">
                    <td colspan="2">
                      <v-simple-table dense class="content-subtable-markets">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                {{ $t("PRICING.TYPE") }}
                              </th>
                              <th class="text-left">
                                {{ $t("PRICING.NAME") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in itemPricing.data"
                              :key="item.productPricingTemplateMarketId"
                            >
                              <td
                                v-if="
                                  item.element_type === 'App\\Models\\Market'
                                "
                              >
                                {{ $t("PRICING.MARKETS") }}
                              </td>
                              <td v-else>
                                {{ $t("PRICING.COUNTRY") }}
                              </td>
                              <td
                                v-if="
                                  item.element_type === 'App\\Models\\Market'
                                "
                              >
                                {{
                                  item.element.name === "-"
                                    ? "(No name)"
                                    : item.element.name
                                }}
                              </td>
                              <td v-else>
                                {{ item.element.nameTranslation }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="productPricingTemplateMarket.length <= 0">
                  <tr>
                    <td colspan="2">{{ $t("GENERAL.NO_DATA") }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row>
              <v-col cols="12">
                <v-btn
                  @click="showDialog()"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("PRICING.ADD_MARKET") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="hideDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            isEditState ? $t("PRICING.EDIT_MARKET") : $t("PRICING.ADD_MARKET")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveForm()" :loading="isLoadingButtonSave">
              {{ $t("GENERAL.SAVE") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          <v-row align="center" justify="end">
            <a
              class="body-2"
              v-if="isShowHelp === false"
              @click="isShowHelp = true"
            >
              {{ $t("GENERAL.SHOW_HELP") }}
            </a>
            <a class="body-2" v-else @click="isShowHelp = false">
              {{ $t("GENERAL.HIDE_HELP") }}
            </a>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-model="isShowHelp"
            type="info"
            border="left"
            elevation="2"
            colored-border
          >
            {{ $t("PRICING.HELP_TEXT") }}
          </v-alert>
          <v-row>
            <v-col cols="12" md="12">
              <SelectorPricing
                :key="keyDialogFullscreen"
                :productPricingTemplateId="productPricingTemplateId"
                ref="formSelectorPricing"
                @data="pricesData"
                :fatherProductPricingTemplateId="productPricingTemplateFatherId"
                :typeTariffTemplate.sync="typeTariffTemplate"
              ></SelectorPricing>
            </v-col>
            <v-col cols="12" md="6">
              <v-tabs fixed-tabs>
                <v-tab>{{ $t("PRICING.MARKETS") }}</v-tab>

                <v-tab-item class="pt-2">
                  <ProductsTreeView
                    :input.sync="marketsSelected"
                    :selection="marketsSelected"
                    :data="marketsToSelect"
                    keyId="marketId"
                  />
                  <div class="v-messages theme--light error--text mt-1">
                    {{ errorsMarkets }}
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
            <v-col cols="12" md="6">
              <v-tabs fixed-tabs>
                <v-tab>{{ $t("PRICING.COUNTRIES") }}</v-tab>

                <v-tab-item class="pt-2">
                  <div class="MarketsCountriesSelector-search">
                    <b-form-input
                      type="search"
                      v-model="search"
                      :placeholder="$t('GENERAL.SEARCH')"
                      @input="handleSearch"
                    ></b-form-input>
                  </div>
                  <div class="MarketsCountriesSelector-items">
                    <v-treeview
                      :error-messages="errors"
                      ref="tree"
                      dense
                      hoverable
                      selectable
                      open-on-click
                      return-object
                      transition
                      v-model="countriesSelected"
                      :items="countriesToSelect"
                      :class="classErrorCountry"
                      :search="search"
                      :open.sync="open"
                      @input="save"
                    ></v-treeview>
                    <div class="v-messages theme--light error--text mt-1">
                      {{ errorsCountry }}
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="
              isShowDialogDelete = false;
              itemIdDelete = null;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="confirmDeleteItem()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectorPricing from "@/view/pages/configure/pricing/components/SelectorPricing";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import { mapValues, reduce, map, sortBy, toString } from "lodash";

const countriesOption = "countries";

export default {
  components: {
    SelectorPricing,
    ProductsTreeView,
  },
  data() {
    return {
      search: "",
      isShowDialog: false,
      isShowDialogDelete: false,
      isShowHelp: true,
      showDetailsTable: false,
      keyDialogFullscreen: 0,
      marketsSelected: [],
      productPricingTemplateId: 0,
      productPricingTemplateIdEdited: 0,
      countriesSelected: [],
      continentsData: [],
      isEditState: false,
      form: {
        isEligibleAllCountries: null,
        eligibleCountries: null,
        eligibleMarkets: null,
      },
      itemIdDelete: null,
      header: [
        {
          text: "Product pricing",
          value: "product_pricing_template.name",
        },
      ],
      typeTariffTemplate: 1,
      TYPE_TARIFF_TEMPLATE_LIST: 1,
      TYPE_TARIFF_TEMPLATE_NEW: 2,
      errorsCountry: null,
      errorsMarkets: null,
      classErrorCountry: "",
      isLoadingButtonSave: false,
    };
  },
  computed: {
    ...mapGetters([
      "Countries",
      "Continents",
      "markets",
      "productPricing",
      "marketPricing",
    ]),
    productPricingTemplateFatherId() {
      return this.$route.params.id;
    },
    cssVars() {
      return {
        "--height-tree": this.height + "px",
      };
    },
    productPricingTemplateMarket() {
      return Object.values(this.marketPricing)?.map(item => {
        return item;
      });
    },
    marketsToSelect() {
      const markets = JSON.parse(JSON.stringify(this.markets));
      if (Object.keys(this.marketPricing).length > 0 && !this.isEditState) {
        return markets?.filter(item => {
          return (
            Object.values(this.marketPricing)[0].markets.indexOf(
              item.marketId
            ) <= -1
          );
        });
      } else {
        return markets;
      }
    },
    countriesToSelect() {
      const continentsData = JSON.parse(JSON.stringify(this.continentsData));
      if (Object.keys(this.marketPricing).length > 0 && !this.isEditState) {
        return continentsData?.filter(item => {
          item.children = item.children.filter(itemChild => {
            return (
              Object.values(this.marketPricing)[0].countries.indexOf(
                itemChild.id
              ) <= -1
            );
          });
          return item.children.length > 0;
        });
      } else {
        return continentsData;
      }
    },
  },
  created() {
    this.formatDataContinents();
  },
  mounted() {
    this.isLoadingButtonSave = false;
    this.fillData();
    this.getMarketPricing(this.productPricingTemplateFatherId);
  },
  methods: {
    ...mapActions([
      "saveMarketPricing",
      "getMarketPricing",
      "deleteMarketPricing",
    ]),
    showDialog() {
      this.isShowDialog = true;
      this.keyDialogFullscreen += 1;
      this.productPricingTemplateId = 0;
      this.errorsCountry = null;
      this.errorsMarkets = null;
      this.classErrorCountry = "";
      this.isLoadingButtonSave = false;
    },
    hideDialog() {
      this.isShowDialog = false;
      this.isEditState = false;
      this.typeTariffTemplate = this.TYPE_TARIFF_TEMPLATE_LIST;
      this.keyDialogFullscreen += 1;
      this.marketsSelected = [];
      this.countriesSelected = [];
      this.isLoadingButtonSave = false;
    },
    async saveForm() {
      let counterErrors = 0;
      const resultValidation = await this.$refs.formSelectorPricing.validateForm();
      if (
        this.marketsSelected.length <= 0 &&
        this.countriesSelected.length <= 0
      ) {
        this.classErrorCountry = "MarketsCountriesSelector-hasErrors";
        this.errorsMarkets = this.$t(
          "PRODUCTS_ADDONS.REQUIRED_MARKETS_COUNTRIES"
        );
        this.errorsCountry = this.$t(
          "PRODUCTS_ADDONS.REQUIRED_MARKETS_COUNTRIES"
        );
        counterErrors++;
      }

      if (!resultValidation || counterErrors > 0) {
        if (resultValidation) {
          this.$bvToast.toast(
            this.$t("PRODUCTS_ADDONS.PLEASE_VERIFY_FIELDS").toString(),
            {
              title: "Error",
              variant: "danger",
              solid: true,
            }
          );
        }

        return false;
      } else {
        this.isLoadingButtonSave = true;
        await this.$refs.formSelectorPricing.savePriceTemplate();

        setTimeout(() => {
          this.saveRequest();
        }, 2000);
      }
    },
    saveRequest() {
      this.getPricingTemplateSaved();
      const formData = {
        productPricingTemplateId: this.productPricingTemplateId,
        productPricingTemplateFatherId: this.productPricingTemplateFatherId,
        countries: this.countriesSelected,
        markets: this.marketsSelected,
        isEdit: this.isEditState,
        productPricingTemplateIdEdited: this.productPricingTemplateIdEdited,
      };
      this.saveMarketPricing(formData).then(() => {
        this.hideDialog();
        this.countriesSelected = [];
        this.marketsSelected = [];
        this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      });
    },
    pricesData(data) {
      this.formPricesComission = data;
    },
    getPricingTemplateSaved() {
      this.productPricingTemplateId = this.productPricing.productPricingTemplateId;
    },
    fillData() {
      if (this.marketsCountriesData) {
        if (this.marketsCountriesData.hasOwnProperty("eligibleCountries")) {
          this.eligibleBy = countriesOption;
          this.countriesSelected = map(
            this.marketsCountriesData.eligibleCountries.split(","),
            data => ({ id: data })
          );
          this.saveCountries();
        }
      }
    },
    formatDataContinents() {
      const mapContinentNames = {
        AFRICA: this.$t("MARKETS.TITLE1"),
        ASIA: this.$t("MARKETS.TITLE2"),
        EUROPE: this.$t("MARKETS.TITLE3"),
        N_AMER: this.$t("MARKETS.TITLE4"),
        OCEAN: this.$t("MARKETS.TITLE5"),
        S_AMER: this.$t("MARKETS.TITLE6"),
      };
      this.continentsData = sortBy(
        reduce(
          mapValues(this.Continents),
          (result, value, key) => {
            if (mapContinentNames[key]) {
              result.push({
                id: key,
                name: mapContinentNames[key],
                children: map(value, country => ({
                  id: country.countryId,
                  name: country.nameTranslation,
                })),
              });
            }
            return result;
          },
          []
        ),
        ["name"]
      );
    },
    handleSearch: function(val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    saveCountries: function() {
      if (this.eligibleBy === countriesOption) {
        const eligibleCountries = toString(map(this.countriesSelected, "id"));
        this.countriesSelected = eligibleCountries;
      }
    },
    deleteItem(data) {
      this.showDetailsTable = false;
      this.itemIdDelete = data;
      this.isShowDialogDelete = true;
    },
    confirmDeleteItem() {
      this.isShowDialogDelete = false;
      this.showDetailsTable = false;
      this.deleteMarketPricing(this.itemIdDelete).then(() => {
        this.showDetailsTable = false;
      });
    },
    editItem(item) {
      this.keyDialogFullscreen += 1;
      this.productPricingTemplateId = item.productPricingTemplateId;
      this.productPricingTemplateIdEdited = item.productPricingTemplateId;
      if (item.name === "-") {
        this.typeTariffTemplate = this.TYPE_TARIFF_TEMPLATE_NEW;
      } else {
        this.typeTariffTemplate = this.TYPE_TARIFF_TEMPLATE_LIST;
      }

      this.isEditState = true;
      this.isShowDialog = true;
      this.isLoadingButtonSave = false;

      item.data?.map(item => {
        if (item.productPricingTemplateId === item.productPricingTemplateId) {
          if (item.element_type === "App\\Models\\Country") {
            this.countriesSelected.push({ id: item.element_id });
          } else if (item.element_type === "App\\Models\\Market") {
            this.marketsSelected.push({ id: item.element_id });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.MarketsCountriesSelector-items {
  max-height: 300px;
  overflow-y: auto;
}
.content-subtable-markets {
  max-height: 300px;
  overflow-y: auto;
}
.content-btn-edit {
}
</style>
