<template>
  <v-card class="mb-2">
    <v-card-title>{{ $t("PRODUCTS_ADDONS.PRICES") }}</v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <validation-observer ref="observerSelector">
            <v-form>
              <!-- Row Field Name -->
              <v-row class="pb-0 mb-0 pt-0 mt-0">
                <v-col cols="12" md="12">
                  <v-radio-group
                    v-model="form.typeTariffTemplate"
                    required
                    row
                    :label="$t('PRODUCTS_ADDONS.SELECT_TYPE_PRICE')"
                  >
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.SELECT_TARIFF_TEMPLATE')"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      :label="$t('PRODUCTS_ADDONS.CREATE_NEW_TARIFF')"
                      :value="2"
                      @click="resetCreationTariff()"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row
                v-show="form.typeTariffTemplate === 1"
                class="pt-0 mt-0 pb-0 mb-0"
              >
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                    rules="required"
                  >
                    <v-autocomplete
                      :items="pricingProducts"
                      item-text="name"
                      item-value="productPricingTemplateId"
                      v-model="form.productPricingTemplateId"
                      dense
                      outlined
                      :error-messages="errors"
                      :label="$t('PRODUCTS_ADDONS.PRICING_TEMPLATE')"
                      required
                      @change="keyPricingTemplate += 1"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  form.productPricingTemplateId && form.typeTariffTemplate === 1
                "
                class="pt-0 mt-0"
              >
                <v-col cols="12">
                  <PricingTemplate
                    :key="keyPricingTemplate"
                    :productPricingTemplateId="form.productPricingTemplateId"
                    :availableOptions="[1, 2, 3, 4]"
                    ref="pricingTemplate"
                    :onlyView="true"
                    :showMessages="false"
                  ></PricingTemplate>
                </v-col>
              </v-row>
              <v-row v-if="form.typeTariffTemplate === 2">
                <v-col cols="12">
                  <PricingTemplate
                    key="formPriceTemplate"
                    :productPricingTemplateId="form.productPricingTemplateId"
                    :availableOptions="[1, 2, 3, 4]"
                    ref="pricingTemplateForm"
                    :showMessages="false"
                  ></PricingTemplate>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    PricingTemplate,
  },
  props: {
    editData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    productPricingTemplateId: {
      type: Number,
      default: 0,
    },
    fatherProductPricingTemplateId: {
      type: Number,
      default: 0,
    },
    typeTariffTemplate: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      errors: [],
      form: {
        productPricingTemplateId: null,
        typeTariffTemplate: null,
        pricingTemplate: {},
      },
      keyPricingTemplate: 0,
      productPricingTemplateIdSaved: 0,
    };
  },
  mounted() {
    this.typeTariffTemplate = !this.typeTariffTemplate
      ? 1
      : this.typeTariffTemplate;
    this.form.typeTariffTemplate = this.typeTariffTemplate;
    this.form.productPricingTemplateId = this.productPricingTemplateId;
  },
  methods: {
    resetCreationTariff() {
      this.form.productPricingTemplateId = 0;
    },
    async savePriceTemplate() {
      if (
        this.form.typeTariffTemplate !== 1 &&
        this.form.typeTariffTemplate !== "1"
      ) {
        let productPricingTemplate = false;
        this.$refs.pricingTemplateForm.validateAndGetData().then(async data => {
          productPricingTemplate = data;
          if (productPricingTemplate) {
            if (productPricingTemplate.productPricingTemplateId === 0) {
              await this.$refs.pricingTemplateForm.store(
                productPricingTemplate
              );
            } else {
              await this.$refs.pricingTemplateForm.save(productPricingTemplate);
              return productPricingTemplate.productPricingTemplateId;
            }

            await this.$refs.pricingTemplateForm
              .validateAndGetData()
              .then(async data => {
                this.productPricingTemplateIdSaved =
                  data.productPricingTemplateId;
              });
          }
        });
        return this.productPricingTemplateSaved;
      } else {
        return this.form.productPricingTemplateId;
      }
    },
    async validateForm() {
      if (this.form.typeTariffTemplate === 1) {
        const validateForm = await this.$refs.observerSelector.validate();
        if (!validateForm) {
          this.$bvToast.toast(
            this.$t("PRODUCTS_ADDONS.PLEASE_VERIFY_FIELDS").toString(),
            {
              title: "Error",
              variant: "danger",
              solid: true,
            }
          );
          return false;
        } else {
          return true;
        }
      } else {
        if (this.$refs.pricingTemplateForm) {
          return await this.$refs.pricingTemplateForm.validateAndGetData();
        } else {
          return this.$refs.pricingTemplateForm;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["productPricings", "productPricing"]),
    pricingProducts() {
      return this.productPricings?.filter(item => {
        return (
          item.isReady === 1 &&
          item.productPricingTemplateId !==
            parseInt(this.fatherProductPricingTemplateId)
        );
      });
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("data", this.form);
      },
      deep: true,
    },
    editData: {
      handler: function() {
        const dataForm = JSON.parse(JSON.stringify(this.editData));
        this.form.typeTariffTemplate = 1;
        if (dataForm.productPricingTemplateId > 0) {
          this.form.productPricingTemplateId =
            dataForm.productPricingTemplateId;
        }
        this.keyPricingTemplate += 1;
      },
      deep: true,
    },
    "form.typeTariffTemplate": {
      handler: function(newValue) {
        if (newValue === 1) {
          this.form.productPricingTemplateId = null;
        }
      },
      deep: true,
    },
    typeTariffTemplate: function() {
      this.keyPricingTemplate += 1;
      this.form.typeTariffTemplate = this.typeTariffTemplate;
      this.form.productPricingTemplateId = this.productPricingTemplateId;
    },
    productPricingTemplateId: function(newValue) {
      if (newValue !== 0) {
        this.form.productPricingTemplateId = newValue;
      }
    },
  },
};
</script>
